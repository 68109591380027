<template>
  <div>
    <app-collapse hover class="mb-1">
      <app-collapse-item title="Filters">
        <!-- :status-filter.sync="statusFilter"
          :status-options="statusOptions" -->
        <PeopleFilters
          :filter-by-designation-id.sync="filterByDesignationId"
          :designation-options.sync="designationOptions"
        />
      </app-collapse-item>
    </app-collapse>

    <b-card class="people-view">
      <b-tabs class="">
        <div class="row">
          <div class="col-12 col-md-6 border-right">
            <div class="d-flex" v-if="people.length">
              <b-input-group class="mb-1 input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  name="search"
                  class="d-inline-block"
                  placeholder="Search Name / Email / Employee ID"
                />
              </b-input-group>

              <!-- <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                text="Filter"
                class="w-25 btn-sm mt-0 pt-0"
              >
                <b-dropdown-form class="">
                  <b-form-group
                    label="Email"
                    label-for="dropdown-form-email"
                    @submit.stop.prevent
                  >
                    <b-form-input
                      id="dropdown-form-email"
                      size="sm"
                      placeholder="email@example.com"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Password"
                    label-for="dropdown-form-password"
                  >
                    <b-form-input
                      id="dropdown-form-password"
                      type="password"
                      size="sm"
                      placeholder="Password"
                    />
                  </b-form-group>

                  <div class="mb-2">
                    <b-form-checkbox> Remember me </b-form-checkbox>
                  </div>
                  <b-button variant="primary" size="sm" @click="onClick">
                    Sign In
                  </b-button>
                </b-dropdown-form>
                <b-dropdown-divider />
                <b-dropdown-item>clear all</b-dropdown-item>
              </b-dropdown> -->
            </div>
            <b-list-group
              class="position-relative"
              ref="div-scroll"
              v-infinite-scroll="loadMore"
              :infinite-scroll-disabled="isBusy"
              infinite-scroll-distance="10"
            >
              <b-overlay :show="isBusy" no-wrap opacity="0.5"></b-overlay>
              <b-list-group-item
                v-for="person in people"
                :key="person.hashid"
                @click="showPerson(person)"
              >
                <!-- :key="person.id" -->
                <div
                  v-if="person"
                  class="d-flex justify-content-between align-items-center"
                >
                <a :href="`/profile/${person.hashid}`" target="_blank">
                  <span class="d-flex">
                    <b-avatar
                      :src="person.avatar"
                      text="avatarText(data.item.name)"
                    />
                    <span class="ml-1">
                      <h4 class="mb-0 text-capitalize">
                        {{ person.name }}
                        <small class="text-muted text-uppercase">
                          (#{{ person.employee_id }})
                        </small>
                      </h4>
                      <p class="mb-0">
                        {{ person.designation }}
                      </p>
                    </span>
                  </span>
                </a>
                  <span @click="addFavorite(person.hashid)">
                    <feather-icon
                      icon="StarIcon"
                      :class="person.is_fav ? 'text-warning' : ''"
                    />
                  </span>
                </div>
              </b-list-group-item>

              <!-- <b-alert :show="people.length < 1" variant="warning" class="p-1">No matching records found.</b-alert> -->
            </b-list-group>
          </div>
          <div class="col-12 col-md-6 border-1">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    :src="userData.avatar"
                    height="80"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <h4>{{ userData.name }}</h4>
                <p class="mb-0">{{ userData.designation }}</p>
                <p class="text-muted">#{{ userData.employee_id }}</p>
                <div></div>
              </b-media-body>
            </b-media>
            <table class="w-100 mt-1">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="MailIcon" class="mr-75" />
                  <span class="font-weight-bold">Email</span>
                </th>
                <td class="pb-50">{{ userData.email }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Designation</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.designation }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold">Join Date </span>
                </th>
                <td>{{ userData.date_of_join | formatDate }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold">Date Of Birth</span>
                </th>
                <td>{{ userData.date_of_birth | formatDateWithoutYear }}</td>
              </tr>
            </table>
          </div>
        </div>
        <b-tab active @click="AddFilter('Starred')">
          <template #title>
            <feather-icon icon="StarIcon" />
            <span>Starred</span>
          </template>
        </b-tab>
        <b-tab @click="AddFilter('MyTeam')">
          <template #title>
            <feather-icon icon="HeartIcon" />
            <span>My Team</span>
          </template>

          <b-card-text> </b-card-text>
        </b-tab>
        <b-tab @click="AddFilter('People')">
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>All</span>
          </template>

          <b-card-text> </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import infiniteScroll from "vue-infinite-scroll";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BAvatar,
  BOverlay,
  BAlert,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import peopleStoreModule from "./peopleStoreModule";
import PeopleFilters from "./PeopleFilters";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
    PeopleFilters,
  },
  directives: {
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      tempFlag: true,
      lastPage: 10,
      nextPage: 1,
      currentPage: 1,
      userData: this.$cookies.get("userData"),
      all_notifications: false,
      allSelected: false,
      is_loading: {
        submit: false,
      },
      redirectEmailNotification: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      subscriptions: [],
      searchQuery: "",
      filterByType: "Starred",
      typeOption: [
        {
          code: "one",
          label: "One",
        },
        {
          code: "many",
          label: "Many",
        },
      ],
      people: [],
      isBusy: false,
      designationOptions: [],
      filterByDesignationId: null,
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-people-store";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, peopleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.fetchDesignationsList();
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }
    if (this.$route.query.filterByType) {
      this.filterByType = this.$route.query.filterByType;
    }
    // this.getPeople();
  },
  mounted() {},
  watch: {
    searchQuery(val) {
      if (val.length > 3 || val.length == 0) {
        this.getPeople();
      }
    },
    filterByType(val) {
      this.getPeople();
    },
    filterByDesignationId(val) {
      this.getPeople();
    },
  },
  methods: {
    loadMore: function () {
      if (
        (this.nextPage === 1 && this.tempFlag === true) ||
        (this.nextPage > 1 && this.tempFlag === false)
      ) {
        this.tempFlag = false;
        if (this.currentPage < this.lastPage) {
          this.isBusy = true;

          let params = {};
          params.page = this.nextPage;
          params.q = this.searchQuery;
          params.type = this.filterByType;
          params.filterByDesignationId = this.filterByDesignationId;

          this.$http
            .post("/people", params)
            .then((res) => {
              this.people = this.people.concat(res.data.data);
              this.nextPage += 1;
              this.lastPage = res.data.meta.last_page;
              this.currentPage = res.data.meta.current_page;
              this.isBusy = false;
            })
            .catch((error) => {
              console.log(error);
              this.isBusy = false;
            });
        }
      }
    },
    AddFilter(type) {
      this.filterByType = type;
    },
    showPerson(person) {
      const self = this;
      self.userData = person;

      return true;
    },
    addFavorite(id) {
      const self = this;
      let params = [];
      params.id = id;
      this.$store
        .dispatch("app-people-store/updateFavorite", params)
        .then((res) => {
          if (res.status == "200") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Favorite Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //   },
            // });
            this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Favorite Updated',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
            this.getPeople();
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Favorite Updated",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Favorite Updated',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateAllSubscription() {
      const self = this;
      let params = [];
      params.subscribed = self.all_notifications;
      this.$store
        .dispatch("app-email-notifications/updateAllSubscription", params)
        .then((res) => {
          if (res.status == "202") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Subscription Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Subscription Updated`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
            this.getUserEmailSubscriptions();
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Subscription Updated",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Subscription Updated',
                icon: 'BellIcon',
                variant: 'danger',
                text:res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPeople() {
      const self = this;
      this.isBusy = true;
      let params = {};
      params.q = self.searchQuery;
      params.type = self.filterByType;
      params.filterByDesignationId = self.filterByDesignationId;
      self.$store
        .dispatch("app-people-store/getPeople", params)
        .then((res) => {
          self.people = res.data.data;
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.currentPage = res.data.meta.current_page;
          if (res.data.data.length > 0) {
            self.userData = res.data.data[0];
          }
          this.isBusy = false;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Something went wrong",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Something went wrong`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          this.isBusy = false;
        });
    },
    fetchDesignationsList() {
      this.isBusy = true;
      this.$store
        .dispatch("app/fetchDesignationsList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
